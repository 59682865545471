import { Box, Flex, Heading, Stack } from "@biblioteksentralen/react";
import { formaterDato } from "@biblioteksentralen/utils";
import { InfoPageData } from "../../app/[locale]/sites/[site]/info/[id]/page";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { DefaultContainer } from "../ContentContainer";
import BlockContent from "../blockContent/BlockContent";
import { Edit } from "../editInSanity/EditInSanity";
import { SanityImageWithResponsiveSize } from "../images/SanityImageWithResponsiveSize";

function StaticPageView({ staticPage }: InfoPageData) {
  const { t, ts } = useTranslation();

  if (!staticPage) return null;

  return (
    <DefaultContainer>
      <Stack direction="column" spacing="1rem">
        <Heading as="h1">{ts(staticPage.title)}</Heading>
        <Edit doc={staticPage} />
        {staticPage.image?.asset && (
          <SanityImageWithResponsiveSize
            image={staticPage.image}
            aspectRatio={1.8}
            resolution={{ base: 540, sm: 1080, lg: 2160 }}
          />
        )}
        <Flex
          justifyContent="space-between"
          paddingTop={{ base: ".5rem", lg: "2rem" }}
          gridGap=" 2rem 5rem"
          flexWrap="wrap"
        >
          <Box whiteSpace="nowrap" fontSize="sm" fontStyle="italic" opacity={0.8}>
            {t("Sist oppdatert")} {formaterDato(staticPage._updatedAt, "PP")}
          </Box>
          <BlockContent blocks={ts(staticPage.body)} />
        </Flex>
      </Stack>
    </DefaultContainer>
  );
}

export default StaticPageView;
